import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import useEmblaCarousel from 'embla-carousel-react';
import { FixedObject } from 'gatsby-image';

import { BREAKPOINTS, COLORS } from '../ui/variables';
import { ReactComponent as PrevIcon } from '../images/icons/left-chevron-orange.svg';
import { ReactComponent as NextIcon } from '../images/icons/right-chevron-orange.svg';
import { ReactComponent as OrangeQuoteIcon } from '../images/icons/orange-quote.svg';
import UserStarAvatar from './UserStarAvatar';
import EmployeeTestimonialAvatar from './EmployeeTestimonialAvatar';
import Blockquote from '../ui/Blockquote';
import { Uppercase } from '../ui/TypographyStyles';

const Container = styled.div`
  position: relative;
  margin-bottom: 80px;
`;

const Title = styled(Uppercase)`
  margin: auto;
  text-align: center;
  margin-bottom: 60px;
  color: ${COLORS.ORANGE};

  @media ${BREAKPOINTS.MOBILE} {
    padding: 0 20px;
  }
`;

const Viewport = styled.div`
  overflow: hidden;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const Testimonial = styled.div`
  position: relative;
  min-width: 100%;
`;

const UserContent = styled.div`
  max-width: 810px;
  padding: 0 60px;
  margin: auto;

  @media ${BREAKPOINTS.TABLET} {
    max-width: 500px;
    padding: 0;
  }

  @media screen and (max-width: 600px) {
    padding: 0 60px;
  }
`;

const Quote = styled(Blockquote)`
  color: ${COLORS.BLACK};
  text-align: center;
  margin-bottom: 80px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 24px;
    line-height: 33px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 20px;
    line-height: 26px;
  }
`;

const AvatarWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;

  svg {
    transition: all 200ms ease;
    width: 32px;

    @media ${BREAKPOINTS.TABLET} {
      right: 24px;
    }

    @media ${BREAKPOINTS.MOBILE} {
      width: 24px;
    }
  }

  &:hover svg {
    transform: scale(1.1);
  }
`;

const NextButton = styled(Button)`
  right: 100px;
  @media ${BREAKPOINTS.TABLET} {
    right: 42px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    right: 16px;
  }
`;

const PrevButton = styled(Button)`
  left: 100px;
  @media ${BREAKPOINTS.TABLET} {
    left: 42px;
  }
  @media ${BREAKPOINTS.MOBILE} {
    left: 16px;
  }
`;

export interface Testimonial {
  name: string;
  position?: string;
  review?: string;
  starCount?: number;
  avatar?: FixedObject;
}

interface Props {
  className?: string;
  reviews: Testimonial[];
  title?: string;
}

const TestimonialsCarousel = ({ title, className, reviews }: Props) => {
  const [viewportRef, embla] = useEmblaCarousel({
    slidesToScroll: 1,
    loop: true,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on('select', onSelect);
    onSelect();
  }, [embla, onSelect]);

  return (
    <Container className={className}>
      <Title>{title}</Title>
      <Viewport ref={viewportRef}>
        <Wrapper>
          {reviews.map(review => (
            <Testimonial key={review.name}>
              <UserContent>
                <OrangeQuoteIcon
                  css={css`
                    margin: auto;
                    display: block;
                    margin-bottom: 80px;
                  `}
                />
                <Quote>{review.review}</Quote>
                <AvatarWrap>
                  {review.starCount && (
                    <UserStarAvatar
                      name={review.name}
                      starCount={review.starCount}
                      fixedImage={review.avatar}
                    />
                  )}

                  {review.position && !review.starCount && (
                    <EmployeeTestimonialAvatar
                      name={review.name}
                      position={review.position}
                      fixedImage={review.avatar}
                    />
                  )}
                </AvatarWrap>
              </UserContent>
            </Testimonial>
          ))}
        </Wrapper>
      </Viewport>
      <PrevButton onClick={scrollPrev} disabled={!prevBtnEnabled}>
        <PrevIcon />
      </PrevButton>

      <NextButton onClick={scrollNext} disabled={!nextBtnEnabled}>
        <NextIcon />
      </NextButton>
    </Container>
  );
};

export default TestimonialsCarousel;
