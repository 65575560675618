import React from 'react';
import { css } from '@emotion/core';
import TestimonialsCarousel, { Testimonial } from '../TestimonialsCarousel';
import { graphql, useStaticQuery } from 'gatsby';

const CareerTestimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      komalThawer: file(
        relativePath: { eq: "pages/careers/komal-thawer-avatar.jpg" }
      ) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      allisonBichoupan: file(
        relativePath: { eq: "pages/careers/allison-bichoupan-avatar.jpg" }
      ) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const teamTestimonials: Testimonial[] = [
    {
      name: 'Komal Thawer',
      position: 'Practice Manager',
      review:
        'I enjoy working for a company that truly wants to see you succeed and help you grow in your career. I started in one position and quickly learned what about my job I enjoyed doing the most. With the support of my manager, I was able to grow into a position that allowed me to develop my leadership skills.',
      avatar: data.komalThawer.childImageSharp.fixed,
    },
    {
      name: 'Allison Bichoupan',
      position: 'Veterinarian',
      review:
        'Small Door has managed to bring together a group of kind, compassionate, professional individuals who are a joy to work with. In a challenging job, it means so much to know that I have an amazing team supporting me every step of the way.',
      avatar: data.allisonBichoupan.childImageSharp.fixed,
    },
  ];

  return (
    <TestimonialsCarousel
      css={css`
        margin-bottom: 100px;
        margin-top: 100px;
      `}
      title='MEET YOUR TEAM'
      reviews={teamTestimonials}
    />
  );
};

export default CareerTestimonials;
